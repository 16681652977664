<template>
    <div class="mt-3">
        
        <div class="row">
            <div class="col-sm-7">
                <b-form>

                    <div class="row mb-3">
                        <div class="col-sm-4">
                            <label>Select Country*</label>
                        </div>
                        <div class="col-sm-8">
                            <b-form-select ref="country" v-model="form.country" :options="country"></b-form-select>
                            <small v-if="error.country && !form.country" class="text-danger">{{error.country}}</small>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-sm-4">
                            <label>MAWB*</label>
                        </div>
                        <div class="col-sm-8">
                            <!-- <b-form-select ref="mawb" v-model="form.mawb" @input="setForm($event)">
                                <option value="">--Select--</option>
                                <option v-for="option, i in options" :key="i" :value="option">
                                    {{option}}
                                </option>
                            </b-form-select> -->
                            <b-form-input ref="mawb" type="text"  v-model="form.mawb" maxlength="25"  @input="setForm()" placeholder="Enter MAWB"></b-form-input>
                            <small v-if="error.mawb && !form.mawb" class="text-danger">{{error.mawb}}</small>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-sm-4">
                            <label>Flight Number*</label>
                        </div>
                        <div class="col-sm-8">
                            <b-form-input ref="flight_no" type="text" v-model="form.flight_no" maxlength="25" placeholder="Enter flight number"  @input="setForm()"></b-form-input>
                            <small v-if="error.flight_no && !form.flight_no" class="text-danger">{{error.flight_no}}</small>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-4">
                            <label>Flight Date*</label>
                            <b-form-datepicker
								@input="setForm()"
                                id="departure_date-datepicker"
                                ref="departure_date"
                                v-model="form.departure_date"
                                class="mb-2"
                                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                            ></b-form-datepicker>
                            <small v-if="error.departure_date && !form.departure_date" class="text-danger">{{error.departure_date}}</small>
                        </div>
                        <div class="col-sm-4">
                            <label>Arrival Date*</label>
                            <b-form-datepicker
								@input="setForm()"
                                id="arrival_date-datepicker"
                                ref="arrival_date"
                                v-model="form.arrival_date"
                                class="mb-2"
                                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                            ></b-form-datepicker>
                            <small v-if="error.arrival_date && !form.arrival_date" class="text-danger">{{error.arrival_date}}</small>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-sm-4">
                            <label>Scan Bag Code</label>
                        </div>
                        <div class="col-sm-8">
                            <div class="input-group">
                                <b-form-input type="text" :disabled="!(form.country && form.mawb)" tabindex="1" autocomplete="off" ref="bagcode" @keydown.tab="scanBagcode" @keydown.enter="scanBagcode" autofocus v-model="bagcode" placeholder="Scan bagcode"></b-form-input>
                                <div class="input-scan">
                                    <svg tabindex="2" xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
                                        <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"></path>
                                    </svg>
                                </div>
                            </div>
                            <small class="mb-3">Note: Please scan <b>Close</b> barcode to close the mawb.</small>
                        </div>
                    </div>

                </b-form>
            </div>
            <div v-if="scannedBags.length > 0" class="col-sm-5">
                <h5 class="mb-3">Scanned Bags <small>({{scannedBags.length}})</small></h5>
                <div style="height: calc(100vh - 150px);overflow: auto;">
                    <span v-for="bag, i in scannedBags" :key="bag" class="bagtag">
                        <span class="tag-body">{{bag}}</span>
                        <span class="tag-tell" @click="deleteBag(i)" title="Delete bag" v-b-tooltip.hover>x</span>
                    </span>
                </div>
            </div>
        </div>

    </div>
</template>


<script>

export default {
    name: 'OutScan',
    title: 'OutScan',
    data() {
        return {
            form: this.$store.getters.global.baggingForm || {country: '', mawb: ''},
            error: {},
            bagcode: '',
            scannedBags: this.$store.getters.global.scannedBags || [],
            country: [
                {value:'', text:'-- Select --'},
                {value:'US', text:'USA'},
                {value:'UK', text:'UK'},
                {value:'NL', text:'Europe'},
                {value:'CA', text:'Canada'},
                {value:'AU', text:'Australia'},
                {value:'SG', text:'Singapore'},
                {value:'AE', text:'UAE'},
                {value:'SA', text:'Saudi Arabia'},
                {value:'ROW', text:'ROW'},
            ],
            mawb: {},
            options: this.$store.getters.global.options || [],
            mawbs: this.$store.getters.global.mawbs || [],
        }
    },

    methods: {
        async deleteBag(i) {
            if(confirm('Are you sure?')) {
                const res = await this.axios.get(`/removeBag/${this.scannedBags[i]}`);
                if(res.data.success) {
                    this.scannedBags.splice(i, 1)
                    this.$store.commit('setGlobal', {'scannedBags': this.scannedBags})
                } else {
                    this.popToast('Failed!', res.data.message, 'danger')
                }
            }
        },
        async scanBagcode() {
            if(this.bagcode == 'close') {
                this.onSubmit()
                return
            }
            if(this.scannedBags.includes(this.bagcode)) {
                this.popToast('Failed!', `Bag ${this.bagcode} already scanned.`, 'danger')
                // Reset bagcode input box for new scan
                setTimeout(() => {
                    this.bagcode = ''
                    this.$refs.bagcode.focus()
                });
            } else {
                this.checkBag()
            }
        },
        async checkBag() {
            try {
                if(this.bagcode) {
                    const res = await this.axios.get(`/outscan/${this.bagcode}/${this.form.mawb}`)
                    if(res.data.success) {
                        this.scannedBags.push(this.bagcode)
                        this.$store.commit('setGlobal', {'scannedBags': this.scannedBags})
                        this.bagcode = ''
                        this.$refs.bagcode.focus()
                        // this.popToast('Success!', res.data.message, 'success')
                    } else {
                        this.popToast('Failed!', res.data.message, 'danger')
                    }
                }
            } catch (err) {
                console.error(err)
            }

            // Reset bagcode input box for new scan
            setTimeout(() => {
                this.bagcode = ''
                this.$refs.bagcode.focus()
            });
        },
        async onSubmit() {
            this.bagcode = ''
            if(!this.form.country) {
                this.error.country = 'Please select country.'
                this.$refs.country.focus()
                return false
            }
            if(!this.form.mawb) {
                this.error.mawb = 'Please enter mawb number.'
                this.$refs.mawb.focus()
                return false
            }
            if(!this.form.flight_no) {
                this.error.flight_no = 'Please enter flight number.'
                this.$refs.flight_no.focus()
                return false
            }
            if(!this.form.departure_date) {
                this.error.departure_date = 'Please enter flight date.'
                this.$refs.departure_date.focus()
                return false
            }
            if(!this.form.arrival_date) {
                this.error.arrival_date = 'Please enter arrival date.'
                this.$refs.arrival_date.focus()
                return false
            }
            if(this.scannedBags.length==0) {
                this.popToast('Failed!', 'You have not scanned any bags yet.', 'danger')
                return false
            }
            let form = Object.assign({}, this.form)
            form.bags = this.scannedBags
            try {
                const res = await this.axios.post('/outscan', form)
                if(res.data.success) {
                        // Delete bags from bagging screen.
                        let closedBags = this.$store.getters.global.closedBags || []
                        closedBags.map((bag, i) => {
                            if(this.scannedBags.includes(bag.bag_code)) {
                                closedBags.splice(i, 1)
                            }
                        });
                        this.$store.commit('setGlobal', {'closedBags': closedBags})

                        // Clear form and save current status.
                        this.form = {country: ''}
                        this.scannedBags = []
                        this.$refs.mawb.value = ''
                        this.$store.commit('setGlobal', {scannedBags:[]})
                        this.bagcode = ''
                        this.$refs.bagcode.focus()
                        this.popToast('Success!', res.data.message, 'success')
                } else {
                    this.bagcode = ''
                    this.$refs.bagcode.focus()
                    this.popToast('Failed!', res.data.message, 'danger')
                }
                this.error = {}
            } catch (err) {
                this.bagcode = ''
                this.$refs.bagcode.focus()
                console.log(err)
            }
        },
       
        setForm() {
            // this.form = Object.assign({}, this.form, this.mawbs.find(i => i.mawb == event))
            this.$store.commit('setGlobal', {baggingForm: this.form, options: [...this.options], mawbs: this.mawbs})
        }
    }
}
</script>


<style lang="scss" scoped>
    thead>tr {
        font-size: 13px;
    }
    tbody td {
        padding: 0 5px;
    }
    tbody tr:first-child input {
        margin-bottom: 8px;
    }
    .bagtag {
        margin-right: 10px;
        margin-bottom: 10px;
        display: inline-flex;
        font-size: 14px;
        box-shadow: 0 0 2px #0000007a;
        .tag-body {
            padding: 7px 20px;
            background: #eee;
        }
        .tag-tell {
            padding: 7px 10px;
            background: #ddd;
            cursor: pointer;
        }
    }
    .form-control[readonly] {
        background: #fff;
        cursor: not-allowed;
    }
</style>
